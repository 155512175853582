import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-wrapper";
import AccessDenied from "../../screens/AccessDeniedScreen";
import Loading from "../../screens/LoadingScreen";

interface Props {
    component: (props: any) => JSX.Element | null;
    path: string;
    exact?: boolean;
}

type Access = "initial" | "loading" | "granted" | "denied";

const PrivateRoute: React.FC<Props> = ({
    component: Component,
    path,
    ...rest
}) => {
    const {
        loading,
        isAuthenticated,
        loginWithRedirect,
        getTokenSilently
    } = useAuth0();
    const [access, setAccess] = useState<Access>("initial");

    useEffect(() => {
        if (isAuthenticated && access === "initial") {
            const authorize = async () => {
                setAccess("loading");
                const token = await getTokenSilently();
                const result = await fetch("/api/authorize", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setAccess(result.ok ? "granted" : "denied");
            };
            authorize();
        }

        if (!isAuthenticated && !loading) {
            const authenticate = async () => {
                await loginWithRedirect({
                    appState: { targetUrl: path }
                });
            };
            authenticate();
        }
    }, [loading, isAuthenticated, loginWithRedirect, path]);

    const render = (props: any) => {
        if (isAuthenticated) {
            switch (access) {
                case "initial":
                case "loading":
                    return <Loading />;
                case "granted":
                    return <Component {...props} />;
                case "denied":
                    return <AccessDenied />;
            }
        }
        return <Loading />;
    };

    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
