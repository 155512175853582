import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { formatHccImbursement, selectIcd10Codes } from "../../../util";
import { AnalyzeResponse } from "api";

interface Props {
    response: AnalyzeResponse;
}

const Icd10Table: React.FC<Props> = ({ response }) => {
    const codes = selectIcd10Codes(response);
    if (!codes || codes.length === 0) {
        return <></>;
    }
    return (
        <Box marginBottom={3}>
            <Box marginBottom={2}>
                <Typography variant="h4" gutterBottom>
                    Potential ICD-10 codes
                </Typography>
                <Typography gutterBottom>
                    The following codes were found by cross referencing medical
                    data from the text with ICD-10 descriptions. The context of
                    each code can be found in the text above.
                </Typography>
            </Box>
            <Box>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ICD-10</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>HCCs</TableCell>
                                <TableCell>Monthly reimbursement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {codes.map(({ code, hccCodes, name }) => (
                                <TableRow key={code}>
                                    <TableCell>{code}</TableCell>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>
                                        {hccCodes.length === 0 && "—"}
                                        {hccCodes
                                            .map(
                                                hccCode =>
                                                    `${hccCode.type}${hccCode.code}`
                                            )
                                            .join(", ")}
                                    </TableCell>
                                    <TableCell>
                                        {formatHccImbursement(hccCodes)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </Box>
    );
};

export default Icd10Table;
