import React, { useState } from "react";
import { TextField, Button, Grid, Theme, CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SearchIcon from "@material-ui/icons/Search";
import { useAuth0 } from "../auth0/react-auth0-wrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%"
        },
        fileInput: {
            display: "none"
        },
        buttonIcon: {
            marginLeft: theme.spacing(1)
        },
        button: {}
    })
);

interface Props {
    analyzeText: (text: string) => void;
    value: string;
    setValue: (text: string) => void;
    loading: boolean;
    errorMessage?: string;
}

const UserInput: React.FC<Props> = ({ analyzeText, value, setValue, loading, errorMessage }) => {
    const classes = useStyles();
    const { getTokenSilently } = useAuth0();
    const [ loadingFile, setLoading ] = useState(false);

    const uploadFile = async (file: File) => {
        setLoading(true);
        if (!file) {
            console.error("File is undefined");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        const token = await getTokenSilently();

        const response = await fetch("/api/extract-text", {
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData,
            method: "POST"
        });
        const responseData = await response.json();

        setValue(responseData.content);
        setLoading(false);
    };

    if (loading || loadingFile) {
        return <CircularProgress />;
    }

    return (
        <Grid spacing={1} container>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    label="Text to analyze"
                    multiline
                    rows="4"
                    margin="none"
                    onChange={event => setValue(event.target.value)}
                    error={!!errorMessage}
                    helperText={errorMessage}
                />
            </Grid>
            <Grid item container xs={12} spacing={1}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!value}
                        onClick={() => analyzeText(value)}
                    >
                        Analyze
                        <SearchIcon className={classes.buttonIcon} />
                    </Button>
                </Grid>
                <Grid item>
                    <label>
                        <input
                            className={classes.fileInput}
                            multiple
                            type="file"
                            onChange={event => {
                                if (event.target.files) {
                                    uploadFile(event.target.files[0]);
                                }
                            }}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            component="span"
                            className={classes.button}
                        >
                            Upload
                            <CloudUploadIcon className={classes.buttonIcon} />
                        </Button>
                    </label>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserInput;
