import React from "react";
import { Paper, Theme, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { formatName } from "../../../util";
import { AnalyzeResponse, EntityCategory } from "api";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(4)
        },
        entity: {
            display: "inline-block",
            margin: theme.spacing(2),
            height: "5em",
            verticalAlign: "top"
        },
        entityText: {
            borderBottom: "2px solid blue",
            marginBottom: "5px",
            fontWeight: "bold",
            display: "inline-block"
        },
        entityIcd10: {
            fontSize: "0.95em"
        },
        entityCategory: {
            color: "#666",
            fontSize: "0.95em"
        },
        entityCategoryUnknown: {
            borderBottomColor: "black"
        },
        entityCategoryTestTreatmentProcedure: {
            borderBottomColor: "red"
        },
        entityCategoryProtectedHealthInformation: {
            borderBottomColor: "blue",
        },
        entityCategoryMedication: {
            borderBottomColor: "green"
        },
        entityCategorymedicalCondition: {
            borderBottomColor: theme.palette.primary.dark
        },
        entityCategoryAnatomy: {
            borderBottomColor: "orange",
        },
        icd10Code: {
            color: "#fff",
            background: "#000",
            padding: 2,
            borderRadius: "5px",
            margin: 1,
            cursor: "help"
        }
    })
);

interface Props {
    response: AnalyzeResponse;
    text: string;
}

const AnalyzedText: React.FC<Props> = ({ response, text }) => {
    const classes = useStyles();

    const components: JSX.Element[] = [];

    let pos = 0;

    response.entities.forEach(
        ({ text: Text, category: Category, type: Type, icd10, beginOffset, endOffset }) => {
            if (pos < beginOffset) {
                components.push(
                    <div className={classes.entity}>
                        <div>&nbsp;</div>
                        <div>{text.substring(pos, beginOffset)}</div>
                    </div>
                );
            }

            const icd10Text = icd10
                ? icd10.codes.map(({ code, name }, i) => (
                      <Tooltip title={name} key={code}>
                          <span className={classes.icd10Code}>{code}</span>
                      </Tooltip>
                  ))
                : null;

            components.push(
                <div className={`${classes.entity}`}>
                    <div className={classes.entityCategory}>
                        {formatName(Category)}
                    </div>
                    <div
                        className={`${classes.entityText} ${
                            classes[getClassName(Category)]
                        }`}
                    >
                        {Text}
                    </div>
                    <div className={classes.entityIcd10}>{icd10Text}</div>
                </div>
            );
            pos = endOffset;
        }
    );

    if (pos < text.length - 1) {
        components.push(
            <div className={classes.entity}>
                <div>&nbsp;</div>
                <div>{text.substring(pos)}</div>
            </div>
        );
    }

    return <Paper className={classes.root}>{components.map((c, i) => <React.Fragment key={i}>{c}</React.Fragment>)}</Paper>;
};

function getClassName(
    category: EntityCategory
): keyof ReturnType<typeof useStyles> {
    switch (category) {
        case "ANATOMY":
            return "entityCategoryAnatomy";
        case "MEDICAL_CONDITION":
            return "entityCategorymedicalCondition";
        case "MEDICATION":
            return "entityCategoryMedication";
        case "PROTECTED_HEALTH_INFORMATION":
            return "entityCategoryProtectedHealthInformation"
        case "TEST_TREATMENT_PROCEDURE":
            return "entityCategoryTestTreatmentProcedure";
        default:
            return "entityCategoryUnknown";
    }
}

export default AnalyzedText;
