import { HccCode, AnalyzeResponse, Entity, Icd10SearchResponse, Icd10Code } from "api";

export function formatName(name: string) {
    name = name.substr(0, 1).toUpperCase() + name.substr(1).toLowerCase();
    name = name.replace(/_/g, ' ');
    return name;
}

export function formatHccImbursement(hccCodes: HccCode[]) {
    const parts = hccCodes.map(hccCode => Math.ceil(hccCode.monthlyReimbursement));
    const sum = Math.ceil(parts.reduce((acc, v) => acc + v, 0));

    return sum > 0
        ? `${parts
              .map(p => formatCurrencty(p))
              .join(" + ")} = ${formatCurrencty(sum)}`
        : "—";
}

export function formatCurrencty(n: number) {
    return n.toLocaleString("en-US", {
        currency: "USD",
        style: "currency",
        minimumFractionDigits: 0
    });
}

export function groupEntities(apiMessage: AnalyzeResponse) {
    return apiMessage.entities.reduce(
        (acc, entity) => {
            if (!acc.hasOwnProperty(entity.category)) {
                acc[entity.category] = [];
            }
            acc[entity.category].push(entity);
            return acc;
        },
        {} as { [key: string]: Entity[] }
    );
}

export function selectIcd10Codes(apiMessage: AnalyzeResponse) {
    const seen = new Set();
    return apiMessage.entities
        .filter(e => e.icd10)
        .map<Icd10SearchResponse>(e => e.icd10)
        .reduce((acc: Icd10Code[], cur) => [...acc, ...cur.codes], [])
        .filter(c => (seen.has(c.code) ? false : seen.add(c.code)))
        .sort((a, b) => a.code.localeCompare(b.code));
}
