import React from "react";
import {
    Container,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Button,
    Typography
} from "@material-ui/core";
import { useAuth0 } from "../components/auth0/react-auth0-wrapper";

export default () => {
    const {
        user: { email },
        logout
    } = useAuth0();
    return (
        <Container maxWidth="sm">
            <Card>
                <CardHeader title="Access denied" />
                <CardContent>
                    <Typography gutterBottom>
                        You do not have access to Cisne Discovery when logged in
                        as <strong>{email}</strong>. If you think this is a
                        mistake, you can try to log out and in again to update
                        your permissions.
                    </Typography>
                    <Typography>
                        Please contact your Cisne representative if the problem
                        persists.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => logout()} color="primary">
                        Logout
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};
