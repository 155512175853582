import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./components/auth0/react-auth0-wrapper";
import "typeface-roboto";

ReactDOM.render(
    <Auth0Provider>
        <App />
    </Auth0Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
