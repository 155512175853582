import React from "react";
import NavBar from "./layout/NavBar";
import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./auth0/PrivateRoute";
import AnalyzerScreen from "../screens/AnalyzerScreen";
import { CssBaseline, createMuiTheme, Box } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme();

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Box>
                <CssBaseline />
                <BrowserRouter>
                    <NavBar />
                    <Switch>
                        <PrivateRoute path="/" exact component={AnalyzerScreen} />
                    </Switch>
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    );
}

export default App;
