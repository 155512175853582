import React from "react";
import AnalyzedText from "./AnalyzedText";
import Icd10Table from "./Icd10Table";
import { Button, Box } from "@material-ui/core";
import { AnalyzeResponse } from "api";

interface Props {
    text: string;
    response: AnalyzeResponse;
    editText: () => void;
}

const Result: React.FC<Props> = ({ response, text, editText }) => (
    <Box>
        <AnalyzedText response={response} text={text} />
        <Icd10Table response={response} />
        <Button onClick={() => editText()} variant="contained" color="primary">
            Edit text
        </Button>
    </Box>
);

export default Result;