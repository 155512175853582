import { Box, Container } from "@material-ui/core";
import React, { useState } from "react";
import { useAuth0 } from "../components/auth0/react-auth0-wrapper";
import Result from "../components/analyzer/result/Result";
import UserInput from "../components/analyzer/UserInput";
import { AnalyzeResponse } from "api";

type View = "input" | "result" | "error";

const AnalyzerScreen = () => {
    const [apiMessage, setApiMessage] = useState<AnalyzeResponse>({
        entities: []
    });
    const [text, setText] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [view, setView] = useState<View>("input");
    const { getTokenSilently } = useAuth0();

    const analyze = async (text: string) => {
        try {
            setLoading(true);
            const token = await getTokenSilently();

            const response = await fetch(`/api/analyze`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ text })
            });

            if (response.ok) {
                const responseData = await response.json();
                setApiMessage(responseData);
                setLoading(false);
                setView("result");
            } else {
                setLoading(false);
                setView("error");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container>
            <Box marginBottom={3}>
                {view === "result" && (
                    <Result
                        response={apiMessage}
                        text={text}
                        editText={() => setView("input")}
                    />
                )}
                {(view === "input" || view === "error") && (
                    <>
                        <UserInput
                            analyzeText={() => analyze(text)}
                            value={text}
                            setValue={value => setText(value)}
                            loading={isLoading}
                            errorMessage={
                                view === "error"
                                    ? "Analysis failed, please try again later or contact support if the problem persists."
                                    : undefined
                            }
                        />
                    </>
                )}
            </Box>
        </Container>
    );
};

export default AnalyzerScreen;
