import React from "react";
import { useAuth0 } from "../auth0/react-auth0-wrapper";
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Typography,
    Theme
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(3)
        },
        title: {
            flexGrow: 1
        }
    })
);

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar>
                {/*
                <IconButton edge="start" color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                */}
                <Typography variant="h6" className={classes.title}>
                    Cisne Discovery
                </Typography>
                {isAuthenticated && (
                    <Button color="inherit" onClick={() => logout()}>
                        Logout
                    </Button>
                )}
                {!isAuthenticated && (
                    <Button
                        color="inherit"
                        onClick={() => loginWithRedirect({})}
                    >
                        Login
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
